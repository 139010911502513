import { useEffect, useState } from 'react';
import Divider from '../../common/Divider';
import PostCard from '../../common/PostCard';
import SectionTitle from '../../common/SectionTitle';
import axios from 'axios';
import PostCardSkeleton from '../../common/PostCardSkeleton';

interface NewsPost {
    id: string;
    slug: string;
    title: string;
    description: string;
    content: string;
    cover: string;
    tag: string;
    created_at: any;
    author: string;
    featured: boolean
}

const Featured = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState<NewsPost[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/posts/featured`);
                setPosts(response.data);
                setLoading(false);
            } catch (error) {
                setError(`${((error) as any).response.data.errorCode}: ${((error) as any).response.data.message}`);
                setLoading(false);
                console.error(error);
            }
        };

        fetchPosts();
    }, []);

    if (error) {
        return <div className='py-16 flex flex-col items-center'>{error}</div>;
    }

    return (
        <section className='featured py-16 flex flex-col items-center'>
            <SectionTitle text={'Uitgelichte Mededelingen'} light={false}/>
            <Divider/>
            <div className={`grid gap-10 pt-8 ${posts.length === 1 ? 'grid-cols-1 place-items-center' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2'}`}>
                {loading && (
                    <>
                        <PostCardSkeleton/>
                        <PostCardSkeleton/>
                    </>
                )}
                {posts.length > 0 &&
                    posts.map((post) => (
                        <PostCard
                            key={post.id}
                            title={post.title}
                            description={post.description}
                            createdAt={post.created_at}
                            slug={post.slug}
                            image={post.cover}
                            tag={post.tag}
                            author={post.author}
                        />
                    ))}

            </div>
            {posts.length === 0 && (
                <div className='flex flex-col items-center text-center'>
                    Er zijn momenteel geen uitgelichte berichten beschikbaar.
                </div>
            )}

        </section>
    );
};

export default Featured;