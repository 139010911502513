import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';
import StaffCard from '../../common/StaffCard';

import PlaceholderImage from '../../../assets/images/placeholder.webp';

const Team = () => {

    return (
        <section className='py-16 flex flex-col items-center'>
            <SectionTitle text={'Sport Bestuur'} light={false} />
            <Divider />
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 py-8'>
                {/* 2x4 bestuur leden */}
                <StaffCard name={'Mark Wouters'} role={'Voorzitter'} picture={PlaceholderImage} email={'mark.wouters@robinhoodboechout.be'} />
                <StaffCard name={'Marleen Sanchez'} role={'Ondervoorzitter'} picture={PlaceholderImage} email={'marleen.sanchez@robinhoodboechout.be'} />
                <StaffCard name={'Eza Capelle'} role={'Secretaris'} picture={PlaceholderImage} email={'eza.capelle@robinhoodboechout.be'} />
                <StaffCard name={'Pieter Markey'} role={'Penningmeester'} picture={PlaceholderImage} email={'pieter.markey@robinhoodboechout.be'} />
                <StaffCard name={'Tony Van Havere'} role={'Leden Beheer'} picture={PlaceholderImage} email={'tony.vanhavere@robinhoodboechout.be'} />
                <StaffCard name={'Ulrik Van Oosterwijck'} role={'Materiaalmeester'} picture={PlaceholderImage} email={'ulrik.vanoosterwijck@robinhoodboechout.be'} />
                <StaffCard name={'Gezocht'} role={'Jeugdbegeleider'} picture={PlaceholderImage} email={''} />
                <StaffCard name={'Yorick Cleerbout'} role={'Webmaster'} picture={PlaceholderImage} email={'yorick.cleerbout@robinhoodboechout.be'} />
            </div>

            <SectionTitle text={'VZW Bestuur'} light={false} />
            <Divider />
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 pt-8'>
                {/* 1x3 VZW leden */}
                <StaffCard name={'Tom Markey'} role={'Voorzitter'} picture={PlaceholderImage} />
                <StaffCard name={'Marleen Sanchez'} role={'Penningmeester'} picture={PlaceholderImage} />
                <StaffCard name={'Pieter Markey'} role={'Secretaris'} picture={PlaceholderImage} />
            </div>

        </section>
    );
};

export default Team;