import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PostBanner from './PostBanner';
import { FaAngleLeft } from 'react-icons/fa';
import SectionTitle from '../../../common/SectionTitle';
import Divider from '../../../common/Divider';
import { PartialBlock } from '@blocknote/core';
import Editor from '../../../common/Editor';

interface PostContentProps {
    slug?: string;
}


interface PostData {
    id: number;
    title: string;
    slug: string;
    description: string;
    tag: string;
    cover: string;
    content: string;
    featured: number;
    author: string;
    created_at: any;
}

const PostContent: React.FC<PostContentProps> = ({ slug }) => {
    const [post, setPost] = useState<PostData | null>(null);
    const [content, setContent] = useState<PartialBlock[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/posts/${slug}`);
                const fetchedPost = response.data[0];

                setPost(fetchedPost);
                setContent(JSON.parse(fetchedPost.content));
            } catch (error) {
                setError('Failed to fetch post');
                console.error(error);
            }
        };

        fetchPost();
    }, [slug]);


    if (error) return <div>Error: {error}</div>;

    if (!post) return <div>No content available</div>;

    return (
        <>
            <section className='py-4 flex flex-col items-center'>
                {post &&
                    <div className='container md:w-1/2'>
                        <a href='/nieuws' className='flex flex-row items-center w-16 text-primary hover:text-secondary font-bold mb-4'>
                            <FaAngleLeft />Terug
                        </a>
                        <PostBanner title={post.title} image={post.cover} created_at={post.created_at} author={post.author} />
                        <div className='p-8 whitespace-pre-wrap bg-gray-50'>
                            <SectionTitle text={post.title} light={false} />
                            <Divider />
                            <Editor content={content} isEditable={false} />
                        </div>
                    </div>

                }
            </section>
        </>
    );
};

export default PostContent;