import axios from 'axios';
import { useState } from 'react';
import { FaFacebook, FaInstagram, FaAngleDoubleRight } from 'react-icons/fa';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NAV_ITEMS = [
    { name: 'Home', path: '/' },
    { name: 'Trainingen', path: '/trainingen' },
    { name: 'Luchtdoelschieten', path: '/luchtdoelschieten' },
    { name: 'Nieuws', path: '/nieuws' },
    { name: 'Agenda', path: '/agenda' },
    { name: 'Lid Worden', path: '/lidgelden' }
]

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value);
    };

    const subscribe = async () => {
        try {
            const response = await axios.post(`https://api.robinhoodboechout.be/api/v1/subscriber`, { 'email': email });

            if (response.status === 201) {
                toast.success('U bent succesvol aangemeld. Controleer uw e-mail om te bevestigen.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        } catch (error) {

            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 409) {
                    toast.warn('Dit e-mail adres is al geabonneerd op onze nieuwsbrief.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                } else {
                    // Handle other statuses and errors
                    toast.error('Er is iets misgegaan. Probeer het opnieuw.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                }
                console.error(error.response?.data || error.message);
            } else {
                console.error(error);
                toast.error('Er is iets misgegaan. Probeer het opnieuw.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        }
    }
    return (
        <>
            <section className='bg-[#191919] py-16 text-primary-text min-h-96 '>
                <div className='max-w-7xl mx-auto flex flex-col md:flex-row justify-between'>
                    <div className='text-center md:text-left'>
                        <h1 className='font-bold text-xl mb-4'>Robin Hood VZW</h1>
                        <p className='py-[2px]'>Borsbeeksesteenweg 45</p>
                        <p className='py-[2px]'>2530 Boechout</p>
                        <p className='py-[2px]'>info@robinhoodboechout.be</p>
                        <p className='py-[2px]'>Onderneming Nr. 0420 875 872</p>
                        <p className='py-[2px]'>BE86 0010 5626 7150</p>
                    </div>
                    <div className='text-center md:text-left mt-8 md:mt-0'>
                        <h1 className='font-bold text-xl mb-4'>Navigatie</h1>
                        <div className='flex justify-center'>
                            <ul className=''>
                                {NAV_ITEMS.map(item => (
                                    <li key={item.path} className='flex items-center py-[2px] hover:text-secondary transition ease-in-out'>
                                        <FaAngleDoubleRight className='mr-2' />
                                        <a href={item.path}>{item.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                    <div>
                        <h1 className='text-center md:text-left font-bold text-xl mb-4 mt-8 md:mt-0'>Volg Ons</h1>
                        <div className='flex items-center justify-center md:justify-start'>
                            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/people/Robin-Hood-Boechout/100057704407940/'>
                                <FaFacebook size={30} className='mr-2' />
                            </a>
                            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/robin_hood_boechout/'>
                                <FaInstagram size={30} className='ml-2' />
                            </a>
                        </div>
                        <h1 className='text-center md:text-left font-bold text-xl mb-4 mt-8'>Nieuwsbrief</h1>

                        <div className='mx-auto flex flex-col h-10 w-full min-w-[200px] max-w-[20rem] '>
                            <input
                                required
                                type='email'
                                id='email'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Email Adres'
                                onChange={e => handleChange(e)}
                            />
                            <button onClick={subscribe} className='mt-3 select-none rounded bg-primary hover:bg-secondary py-2 px-4 text-center align-middle text-xs font-bold uppercase text-white shadow-md transition-all focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none'>
                                Abonneren
                            </button>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </section>
            <footer className='bg-primary min-h-20 text-primary-text text-sm font-bold flex flex-col md:flex-row items-center '>
                <div className='md:w-1/2 flex justify-center pt-8 md:pt-0'>
                    <p>© {new Date().getFullYear()} Robin Hood Boechout</p>
                </div>
                <div className='md:w-1/3 flex flex-wrap  justify-evenly text-center py-4 md:py-0'>
                    <a href={'/reglement'}>Club Reglement </a>
                    <a href={'/gedragscode'}>Gedragscode</a>
                    <a href={'/privacy'}>Privacy</a>
                    <a href={'/seksuele-intimidatie'}>Seksuele Intimidatie</a>
                </div>

            </footer>

        </>
    );
};

export default Footer;