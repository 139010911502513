import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FaMapMarkerAlt, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';

import MapImage from '../../../assets/images/map.png';

const Contact = () => {
    const [formData, setFormData] = useState({ email: '', message: '', });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        setError('');
        setSuccess('');

        if (!executeRecaptcha) {
            setError('reCAPTCHA is not ready yet. Please try again.');
            setLoading(false);
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha('contact_form');

            const response = await fetch('https://api.robinhoodboechout.be/api/v1/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, 'recaptcha_token': recaptchaToken }),
            });

            if (!response.ok) {
                throw new Error('Failed to send message.');
            }

            setSuccess('Bedankt voor uw bericht, wij nemen zo snel mogelijk contact met u op.');
            setFormData({ email: '', message: '' });
        } catch (err: any) {
            setError(err.message || 'Er is iets mis gegaan, probeer het later opnieuw.');
        } finally {
            setLoading(false);
        }
    };

    return (

        <section className='py-16 flex flex-col items-center bg-[#181818] bg-fixed bg-cover bg-center bg-no-repeat' style={{ backgroundImage: `url(${MapImage})` }}>
            <SectionTitle text={'Bezoek Ons'} light={true} />
            <Divider />
            {/* Contact Details  (Left)*/}
            <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10 pt-8 w-3/4'>
                <div className='p-8 text-primary-text'>
                    <div className='flex items-center mb-4'>
                        <h2 className='text-base font-bold'>Contacteer Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <div className='flex items-center'>
                            <FaMapMarkerAlt size={20} className='mr-4' />
                            <address className='not-italic'>
                                <p>Borsbeeksesteenweg 45, 2530</p>
                                <p>Boechout</p>
                            </address>
                        </div>
                        <a href='mailto:info@robinhoodboechout.be' className='flex items-center'>
                            <FaEnvelope size={20} className='mr-4' />
                            <p>info@robinhoodboechout.be</p>
                        </a>
                    </div>
                    {/* Google Maps Section */}
                    <div className='flex items-center mt-6 mb-4'>
                        <h2 className='text-base font-bold'>Vind Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <iframe className='w-full h-60' loading='lazy' src='https://maps.google.com/maps?q=Robin%20Hood%20Boechout&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near' title='Robin Hood Boechout' aria-label='Robin Hood Boechout'></iframe>
                    </div>
                    {/* Socials Section */}
                    <div className='flex items-center mt-6 mb-4'>
                        <h2 className='text-base font-bold'>Volg Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <div className='flex items-center'>
                            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/people/Robin-Hood-Boechout/100057704407940/'>
                                <FaFacebook size={30} className='mr-2' />
                            </a>
                            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/robin_hood_boechout/'>
                                <FaInstagram size={30} className='ml-2' />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Contact Form  (Right)*/}
                <div className='flex justify-center items-center'>
                    <form className='bg-white p-8 rounded shadow-md w-full max-w-md' onSubmit={handleSubmit}>
                        <SectionTitle text={'Contacteer Ons'} light={false} />
                        <Divider />

                        {error && <p className='p-2 text-red-500 text-sm font-bold'>{error}</p>}
                        {success && <p className='p-2 text-secondary text-sm font-bold'>{success}</p>}

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                                Email Adres
                            </label>
                            <input
                                required
                                type='email'
                                id='email'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Email Adres'
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='mb-6'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='message'>
                                Bericht
                            </label>
                            <textarea
                                required
                                id='message'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Type uw bericht hier...'
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <div className='flex items-center justify-between'>
                            <button
                                type='submit'
                                className='px-12 py-4 border-10 uppercase bg-primary hover:bg-secondary rounded-full text-sm font-bold text-primary-text duration-300 ease-in-out transform cursor-pointer'
                                disabled={loading}
                            >
                                {loading ? 'Verzenden...' : 'Verzenden'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    );
};

export default Contact;