import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

interface StaffCardProps {
    name: string;
    role: string;
    picture: string;
    email?: string;
}

const StaffCard: React.FC<StaffCardProps> = ({ name, role, picture, email }) => {

    return (
        <>
            <div className='flex flex-col md:flex-col gap-6 md:gap-8'>
                <img className='rounded-full border-4 border-gray-300 h-28 w-28 mx-auto' src={picture} alt={name} />
                <div className='flex flex-col text-center items-center'>
                    <div className='font-medium text-lg text-center text-gray-800'>{name}</div>
                    <div className='text-gray-500 mb-3 text-center whitespace-nowrap'>{role}</div>
                    <div className='text-gray-800 my-auto text-2xl mx-auto md:mx-0'>
                        {email &&
                            <a className='text-gray-500 hover:cursor-pointer hover:text-accent' href={`mailto:${email}`}>
                                <FaEnvelope size={20} />
                            </a>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaffCard;