import { useEffect, useState } from 'react';
import Divider from '../../common/Divider';
import HoursCard from '../../common/HoursCard';
import SectionTitle from '../../common/SectionTitle';
import axios from 'axios';

interface TrainingsProps {
    id: string;
    season_id: number;
    day: string;
    hours: string;
    details: string;
}

const Trainings = () => {
    const [winterTrainings, setWinterTrainings] = useState<TrainingsProps[]>([]);
    const [summerTrainings, setSummerTrainings] = useState<TrainingsProps[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchTrainings = async () => {
            try {
                const responseWinter = await axios.get(`https://api.robinhoodboechout.be/api/v1/trainings/winter`);
                const responseSummer = await axios.get(`https://api.robinhoodboechout.be/api/v1/trainings/zomer`);
                setWinterTrainings(responseWinter.data.trainings);
                setSummerTrainings(responseSummer.data.trainings);
            } catch (error) {
                setError('Failed to fetch training details');
                console.error(error);
            }
        };

        fetchTrainings();
    }, []);

    if (error) {
        return <div className='py-16 flex flex-col items-center'>Error: {error}</div>;
    }

    return (
        <section className='py-16 md:min-h-[45vh] min-h-[40vh] '>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 pt-8'>
                <div className='flex flex-col items-center text-center'>
                    <SectionTitle text={'Tijdens de Winter'} light={false} />
                    <Divider />
                    <p className='w-1/2 text-sm font-normal tracking-wide italic'>In de winter kan er op onze binnenstand geschoten worden vanaf 12 meter t.e.m 25 meter.</p>
                    <HoursCard hours={winterTrainings} />
                </div>
                <div className='flex flex-col pt-8 md:pt-0 items-center text-center'>
                    <SectionTitle text={'Tijdens de Zomer'} light={false} />
                    <Divider />
                    <p className='w-1/2 text-sm font-normal tracking-wide italic'>In de zomer kan er op onze buitenstand geschoten worden vanaf 12 meter t.e.m 70 meter.</p>
                    <HoursCard hours={summerTrainings} />
                </div>
            </div>
        </section>
    );
};

export default Trainings;