import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Gallery() {
    const [photos, setPhotos] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    try {
        useEffect(() => {
            const fetchAlbum = async () => {
                const galleryImages = [];
                try {
                    const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/albums/luchtdoelschieten`);
                    for (const image of response.data.images) {
                        galleryImages.push(`https://cdn.robinhoodboechout.be${response.data.folder}/${image.filename}`);
                    }
                    setPhotos(galleryImages);
                } catch (error) {
                    setError('Failed to fetch album.');
                    console.error(error);
                }
            };

            fetchAlbum();
        }, []);
    } catch (error) {
        console.error(error);
    }
    return (
        <>
            <section className='mx-auto py-16 flex flex-col items-center w-3/4'>
                <SectionTitle text={'Foto Galerij'} light={false} />
                <Divider />
                <div className='columns-1 sm:columns-2 lg:columns-4 py-10 md:py-10 gap-4'>
                    {error}
                    {!error && photos.map((src, index) => (
                        <div key={index} className='mb-4 break-inside-avoid'>
                            <img alt='' src={src} className='w-full object-cover rounded-lg' />
                        </div>
                    ))}
                </div>
            </section >
        </>
    );
}